<template>
  <div class="section1">
      <div class="main">
          
          <div class="content">
			  <router-link to="/index">Back</router-link>
          </div>
      </div>
  </div>
</template>

<script>

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.content{
	width: 100%;text-align: center;
}
</style>
